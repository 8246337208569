form label {
    color: var(--brown);
    text-transform: uppercase;
}

form .hint {
    color: var(--light-brown);
    font-size: 14px;
    margin: 5px 0 10px 0;
    padding: 0;
}

form select,
form input {
    width: 100%;
    background: var(--form-element-bkg);
    border: 1px solid var(--form-element-border);
    color: var(--form-element-color);
    font-size: 16px;
    padding: 10px 20px;
    font-family: 'OriyaMN';
}

form input {
    width: calc(100% - 40px);
}

form select:focus,
form textarea:focus,
form input:focus {
    border: none;
    outline: inset;
}

form textarea {
    width: calc(100% - 40px);
    background: var(--form-element-bkg);
    border: 1px solid var(--form-element-border);
    color: var(--form-element-color);
    font-size: 16px;
    padding: 10px 20px;
    font-family: 'OriyaMN';
}

form .btn-group {
    justify-content: center;
    margin: 25px 0 10px 0;
}