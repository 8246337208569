/* Box Black */

.box-black {
    flex-grow: 1;
    background: var(--box-black-bkg);
    border: 1px solid var(--box-black-border);
    border-radius: 8px;
    text-align: center;
    padding-bottom: 30px;
    margin: 10px;
    text-decoration: none;
}

.box-black:hover,
.box-black:focus {
    border: 1px solid var(--pink) !important;
}

.box-black:hover .btn-pink,
.box-black:focus .btn-pink {
    background: linear-gradient(to bottom, var(--pink-gradient-from) 0%, var(--pink-gradient-to) 50%);
}

.box-black:active .btn-pink {
    background: linear-gradient(to top, var(--pink-gradient-from) 0%, var(--pink-gradient-to) 100%);
}

.box-black.highlighted {
    border: 1px solid var(--box-black-highlight-border);
}

.box-black h2 {
    color: var(--pink);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.box-black h3 {
    color: var(--teal);
    margin-top: 30px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: normal;
    font-size: 22px;
}

.box-black>p {
    max-width: 850px;
    margin: 0 auto;
    color: var(--light-text);
    letter-spacing: 1px;
    line-height: 25px;
    text-align: center;
}

.box-black .box-black-hint {
    margin-bottom: 30px;
}

.box-black .box-black-hint>p {
    color: var(--light-text);
    margin: 0;
}

.box-black-row {
    display: flex;
}

@media(max-width: 786px) {
    .box-black {
        padding: 20px;
    }

    .box-black-row {
        flex-direction: column;
    }

    .box-primary-row {
        flex-direction: column;
    }

    .box-brown {
        flex-direction: column;
    }

    .box-brown .right p {
        padding: 5vw;
    }
}

/* Box Brown */

.box-brown-outer {
    border: 1px solid var(--box-brown-outer-border);
    background: var(--box-brown-outer-bkg);
    border-radius: 8px;
    padding: 15px;
}

.box-brown {
    display: flex;
    text-align: center;
    border: 1px solid var(--box-brown-border);
    background: linear-gradient(to bottom, var(--brown-gradient-from) 50%, var(--brown-gradient-to) 100%);
}

.box-brown .left img {
    margin: 0 auto;
}

.box-brown .left {
    align-self: center;
}

.box-brown .right {
    width: 100%;
}

.box-brown h2 {
    font-weight: regular;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--pink);
}

.box-brown p {
    color: var(--brown);
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
    line-height: 2;
    font-size: 18px;
}

/* Box Primary */

.box-primary-row {
    display: flex;
}

.box-primary-outer {
    border: 1px solid var(--box-primary-outer-border);
    background: var(--box-primary-outer-bkg);
    border-radius: 8px;
    padding: 15px;
}

.box-primary {
    border: 1px solid var(--box-primary-border);
    background: var(--box-primary-bkg);
    padding: 20px;
}

.box-primary-outer.right {
    display: flex;
    flex-direction: column;
}

.box-primary-outer h3 {
    color: var(--brown);
    font-weight: normal;
    letter-spacing: 1px;
    margin: 0;
}

.box-primary-outer p {
    color: var(--light-brown);
    text-align: left;
    line-height: 1.8;
    margin: 0 auto;
    padding: 10px 0;
}
