.tab-group {
  display: flex;
}

.tab-group .tab {
  flex: 1;
  background: var(--tab-bkg);
  border: 1px solid var(--tab-border);
  color: var(--tab-color);
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 18px;
  font-family: OriyaMN;
  letter-spacing: 1px;
  cursor: pointer;
}

.tab-group .tab:hover,
.tab-group .tab:focus {
  opacity: 0.8;
}

.tab-group .tab.selected {
  border-color: var(--pink);
}
