body {
    margin: 0;
    padding: 0;
    color: var(--bright-text);
    background-color: var(--bkg);
    font-family: OriyaMN;
}

/* Containers */
.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    display: flex;
    flex: 1;
    flex-flow: wrap;
    max-width: 80vw;
    margin: 0 auto;
}

.header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    align-self: flex-start;
    flex-direction: row;
    width: 100vw;
}

.header nav.mobile {
    display: none;
    position: absolute;
    left: 8vw;
    top: 3vh;
}

.header nav.mobile > a {
    color: #ffffff;
    text-decoration: none;
    font-size: 30px;
}

.content {
    flex: 1;
    min-height: 80vh;
}

.footer {
    background-color: var(--footer-bkg);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    flex-direction: row;
    padding: 5px;
    margin-top: -30px;
    flex: 1;
}

@media(max-width: 768px) {
    .main {
        max-width: 95vw;
    }
    
    .header {
        flex-flow: column;
    }

    .header nav.desktop {
        display: none;
    }

    .header nav.mobile {
        display: block;
    }

    .footer {
        flex-direction: column;
        margin-top: -10px;
    }
}