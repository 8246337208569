@font-face {
  font-family: "OriyaMN";
  font-style: normal;
  font-weight: normal;
  src: url("../font/OriyaMN.ttf") format("truetype");
}

@font-face {
  font-family: "OriyaMN";
  font-style: normal;
  font-weight: bold;
  src: url("../font/OriyaMN-Bold.ttf") format("truetype");
}