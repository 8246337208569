.hero {
    max-width: 650px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-title {
    color: var(--teal);
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 2px;
    margin: 0;
}

.hero-description {
    color: var(--light-text);
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1.2px;
    text-align: justify;
    line-height: 25px;
}

.btn-hero {
    border-radius: 36px;
    padding: 20px 40px;
    text-transform: uppercase;
    text-align: center;
    margin-right: auto;
}

.start-writing-section {
    margin-top: 50px;
}

.start-writing-button {
    padding: 12px 24px;
    background-color: #1d4ed8;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}


.email-signup-section {
    margin-top: 40px;
}

.email-signup-section h2 {
    font-size: 24px;
    font-weight: bold;
}

.email-signup-section p {
    color: var(--light-text);
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1.2px;
    text-align: justify;
    line-height: 25px;
}

.signup-form {
    margin-top: 20px;
}

.signup-form input[type="email"], .contact-form input {
    padding: 10px;
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.contact-form input{
    display: block;
    width: 300px;
    margin: 12px 0;
}

.contact-form textarea{
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 300px;
    padding: 10px;
    height: 200px;
    resize:none;
}

.signup-form button, .contact-form button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.contact-form button{
    display: block;
    margin: 15px 0;
}


.signup-form button.disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
}

@media(min-width: 787px) {
    .hero {
        /* margin-top: -100px; */
    }
}

@media(max-width: 786px) {
    .hero {
        background: #000000dd;
        max-width: 90vw;
        margin: 2vw 2vw 2vw 2vw;
        justify-content: flex-start;
        height: auto;
        padding: 8vw;
        border-radius: 8px;
    }

    .hero-description {
        text-align: left;
        ;
        line-height: 22px;
    }

    .btn-hero {
        padding: 14px 17px;
        width: 50vw;
        font-size: 12px;
    }



}


@media (max-width: 600px) {

    .signup-form input[type="email"] {
        width:95%
    }

    .signup-form button {
        margin: 1rem 0;
    }
}