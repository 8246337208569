.btn {
    color: var(--button-text);
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 15px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    font-family: OriyaMN;
    text-shadow: 1px 1px 1px #00000044;
}

.btn-sm {
    padding: 5px 15px;
    font-size: 12px;
    text-transform: none;
    font-weight: normal;
    color: #aaa;
}

.btn-dull-text {
    color: #aaa;
}

.btn-group {
    display: flex;
    gap: 10px;
}

.btn-group.btn-group-align-right {
    justify-content: right;
}

.btn-nav {
    margin: 0 4px;
}

.btn-footer {
    color: var(--button-text);
    text-decoration: none;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 1px;
}

.btn-footer:hover {
    color: var(--bright-text);
}

.btn-footer-social {
    text-decoration: none;
    margin: 10px;
    opacity: 0.5;
}

.btn-footer-social:hover {
    opacity: 1;
}

.btn-pink {
    background: linear-gradient(to bottom, var(--pink-gradient-from) 0%, var(--pink-gradient-to) 100%);
}

.btn-pink:hover,
.btn-pink:focus {
    background: linear-gradient(to bottom, var(--pink-gradient-from) 0%, var(--pink-gradient-to) 50%);
}

.btn-pink:active {
    background: linear-gradient(to top, var(--pink-gradient-from) 0%, var(--pink-gradient-to) 100%);
}

.btn-pink[disabled] {
    background: linear-gradient(to bottom, var(--pink-gradient-disabled-from) 0%, var(--pink-gradient-disabled-to) 100%);
}

/* .btn-pink-border {
    border: 1px solid var(--pink);
} */

.btn-gold {
    background: linear-gradient(to bottom, var(--gold-gradient-from) 0%, var(--gold-gradient-to) 100%);
}

.btn-gold:hover,
.btn-gold:focus {
    background: linear-gradient(to bottom, var(--gold-gradient-from) 0%, var(--gold-gradient-to) 50%);
}

.btn-gold:active {
    background: linear-gradient(to top, var(--gold-gradient-from) 0%, var(--gold-gradient-to) 100%);
}

/* .btn-gold-border {
    border: 1px solid var(--gold);
} */

.btn-red {
    background: linear-gradient(to bottom, var(--red-gradient-from) 0%, var(--red-gradient-to) 100%);
}

.btn-red:hover,
.btn-red:focus {
    background: linear-gradient(to bottom, var(--red-gradient-from) 0%, var(--red-gradient-to) 50%);
}

.btn-red:active {
    background: linear-gradient(to top, var(--red-gradient-from) 0%, var(--red-gradient-to) 100%);
}

/* .btn-red-border {
    border: 1px solid var(--red);
} */

.btn-blue {
    background: linear-gradient(to bottom, var(--blue-gradient-from) 0%, var(--blue-gradient-to) 100%);
}

.btn-blue:hover,
.btn-blue:focus {
    background: linear-gradient(to bottom, var(--blue-gradient-from) 0%, var(--blue-gradient-to) 50%);
}

.btn-blue:active {
    background: linear-gradient(to top, var(--blue-gradient-from) 0%, var(--blue-gradient-to) 100%);
}

/* .btn-blue-border {
    border: 1px solid var(--blue);
} */

@media(max-width: 786px) {
    .btn-group {
        flex-wrap: wrap;
        justify-content: center !important;
    }
}