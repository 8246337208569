.accordion {
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.accordion h3:before {
  content: "\25ed";
  float: right;
  color: var(--brown);
}

.accordion.collapsed h3:before {
  content: "\29e8";
}

.accordion h3:hover {
  color: var(--light-text);
}
.accordion.collapsed:hover h3 {
  color: var(--light-text);
}