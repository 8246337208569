:root {
    --bkg: #120D14;
    --teal: #2BD3B0;
    --blue: #1197BB;
    --pink: #AD4F76;
    --maroon: #8D5F5E;
    --brown: #A4836C;
    --light-brown: #674F51;

    --bright-text: #ffffff;
    --light-text: #989696;
    --button-text: #C4C4C4;

    --box-primary-outer-bkg: #251C27;
    --box-primary-outer-border: #2A272B;
    --box-primary-bkg: #231A25;
    --box-primary-border: #29222B;

    --box-brown-outer-bkg: #2D2021;
    --box-brown-outer-border: #201919;
    --box-brown-border: #382C2B;

    --box-black-bkg: #000000cc;
    --box-black-border: #979797;
    --box-black-highlight-border: var(--teal);

    --form-element-bkg: #201620;
    --form-element-border: #1C171C;
    --form-element-color: #8D5F5E;

    --tab-bkg: #201620;
    --tab-border: #1C171C;
    --tab-color: #8D5F5E;

    --pink-gradient-from: #954A69;
    --pink-gradient-to: #612342;
    --pink-gradient-disabled-from: #954A6944;
    --pink-gradient-disabled-to: #61234244;
    --gold-gradient-from: #BB8711;
    --gold-gradient-to: #5C3D04;
    --red-gradient-from: #BB1111;
    --red-gradient-to: #5C0404;
    --blue-gradient-from: #1197BB;
    --blue-gradient-to: #04515C;
    --brown-gradient-from: #271B1F;
    --brown-gradient-to: #382926;

    --footer-bkg: #00000088;
}